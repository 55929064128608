import React, { useState } from "react"
import "./App.css"
import "bootstrap/dist/css/bootstrap.min.css"
import Header from "./component/Header"
import Menu from "./component/Menu"
import Footer from "./component/Footer"
import Introduction from "./page/Introduction"
import Authentication from "./page/Authentication"
import GetJobs from "./page/GetJobs"
import AcceptJob from "./page/AcceptJob"
import AddAppointment from "./page/AddAppointment"
import QuoteJob from "./page/QuoteJob"
import UpdateAppointment from "./page/UpdateAppointment"
import UpdateWorkRequestStatus from "./page/UpdateWorkRequestStatus"
import CompleteJob from "./page/CompleteJob"
import AddFile from "./page/AddFile"
import SetPlannedVisitDate from "./page/SetPlannedVisitDate"
import AddVisitDate from "./page/AddVisitDate"
import AddMessage from "./page/AddMessage"
import AddCost from "./page/AddCost"
import AddPPMVisit from "./page/AddPPMVisit"
import GetPlanners from './page/GetPlanners'

function App() {
  const [selectedpage, setSelectedpage] = useState("Introduction")

  const setMenuItem = (menuItem) => {
    setSelectedpage(menuItem)
  }

  return (
    <React.Fragment>
      <div className="wrapper">
        <Header></Header>

        <div className="container-fluid">
          <div className="row">
            <div className="col-2 p-0 menuwrapper">
              <Menu setMenuItem={setMenuItem}></Menu>
            </div>
            <div className="col-10">
              {selectedpage === "Introduction" && <Introduction></Introduction>}

              <div className="row">
                <div className="col">
                  {selectedpage === "Authentication" && (
                    <Authentication></Authentication>
                  )}
                  {selectedpage === "GetJobs" && <GetJobs></GetJobs>}
                  {selectedpage === "AddCost" && <AddCost></AddCost>}
                  {selectedpage === "AcceptJob" && <AcceptJob></AcceptJob>}

                  {selectedpage === "AddAppointment" && <AddAppointment></AddAppointment>}
                  {selectedpage === "QuoteJob" && <QuoteJob></QuoteJob>}
                  {selectedpage === "UpdateAppointment" && <UpdateAppointment></UpdateAppointment>}
                  {selectedpage === "UpdateWorkRequestStatus" && <UpdateWorkRequestStatus></UpdateWorkRequestStatus>}
                  {selectedpage === "CompleteJob" && <CompleteJob></CompleteJob>}


                  {selectedpage === "AddFile" && <AddFile></AddFile>}
                  {selectedpage === "AddPPMVisit" && <AddPPMVisit></AddPPMVisit>}
                  {selectedpage === "GetPlanners" && <GetPlanners></GetPlanners>}
                 
                  {selectedpage === "SetPlannedVisitDate" && (
                    <SetPlannedVisitDate></SetPlannedVisitDate>
                  )}
                  {selectedpage === "AddVisitDate" && (
                    <AddVisitDate></AddVisitDate>
                  )}
                  {selectedpage === "AddMessage" && <AddMessage></AddMessage>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </React.Fragment>
  )
}

export default App
