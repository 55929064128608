import React from "react"
import "./getjobs.css"

function QuoteJob() {
  return (
    <React.Fragment>
      <h4 className="pt-2">Quote Job</h4>
      <div className="row">
        <div className="col-8">
          <p className="pt-2">
            This API will allow quotes to be provided against a job number. The quote, if approved, will create
            a new work request id which will be available in the "GetJobs" api as a new "job".  The JobNumber will not change, but a new work request id will be set
          </p>
          <p>The quote value ex.VAT should be a combined total of the <code>call out</code> cost, <code>labour</code> cost and <code>material</code> cost.</p>
          <p>If these arent applicable, please select a field to add the total value, and default the others to 0.</p>
          <p>A detailed breakdown of costs, as well as quote details could be provided in the message text</p>
          <div className="alert alert-success" role="alert">
            <span className="badge text-bg-success">POST</span>{" "}
            <span className="ps-2">
              <code>/api/v2/quotejob</code>
            </span>
          </div>
          <h4>Security</h4>
          <div className="alert alert-light" role="alert">
            API Key
            <p>
              <code>Authorization</code> and <code>ClientKey</code> keys are
              required in the header. See Authentication
            </p>
          </div>
          <h4>Parameters</h4>
          <div className="alert alert-light" role="alert">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Parameter</th>
                  <th scope="col">Description</th>
                  <th scope="col">Parameter Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <code>
                      <b className="text-dark">jobNumber</b>
                    </code>
                  </th>
                  <td>
                    <code className="text-dark">
                      The job number of the job relating to the quote
                    </code>
                  </td>
                  <td>
                    <code className="text-dark">body</code>
                  </td>
                </tr>



                <tr>
                  <th scope="row">
                    <code className="text-dark">calloutCost</code>
                  </th>
                  <td>
                    <code className="text-dark">
                      
                    </code>
                    <br />
                    <code className="text-dark">example</code>{" "}
                    <code>0</code>
                  </td>
                  <td>
                  <code className="text-dark">body</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code className="text-dark">labourCost</code>
                  </th>
                  <td>
                    <code className="text-dark">
                      
                    </code>
                    <br />
                    <code className="text-dark">example</code>{" "}
                    <code>366</code>
                  </td>
                  <td>
                  <code className="text-dark">body</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code className="text-dark">materialCost</code>
                  </th>
                  <td>
                    <code className="text-dark">
                     
                    </code>
                    <br />
                    <code className="text-dark">example</code>{" "}
                    <code>56.78</code>
                  </td>
                  <td>
                  <code className="text-dark">body</code>
                  </td>
                </tr>




                <tr>
                  <th scope="row">
                    <code className="text-dark">message</code>
                  </th>
                  <td>
                    <code className="text-dark">
                      information relating to the quote.  This could include a breakdown of costs
                    </code>
                  </td>
                  <td>
                    <code className="text-dark">body</code>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="card text-bg-light mb-3">
            <div className="alert alert-success pb-2 pt-2" role="alert">
              <h5>
                {" "}
                <span className="largefont"> &#8226;</span> 200
              </h5>
            </div>

            <div className="card-body">Success</div>
          </div>
        </div>
        <div className="col-4">
          <div className="alert alert-light" role="alert">
            <section>
              <code>
                curl --location
                'http://localhost:8080/api/addmessage/:workRequestId' \
                --header 'Authorization: AUTH_KEY' \ --header 'ClientKey:
                CLIENT_KEY' \ --header 'Content-Type: text/plain' \ --data
                'NOTE_TEXT'
              </code>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default QuoteJob
