import React from "react"
import "./getjobs.css"

function CompleteJob() {
  return (
    <React.Fragment>
      <h4 className="pt-2">Financial Completition of Job</h4>
      <div className="row">
        <div className="col-8">
          <p className="pt-2">
            The last process in the job lifecycle is to complete the job by providing final cost information
          </p>
          <p>
            Its recommended to add the invoice number to the message
          </p>
          <p>Messages can be added to a Job regardless of the job status</p>
          <div className="alert alert-success" role="alert">
            <span className="badge text-bg-success">POST</span>{" "}
            <span className="ps-2">
              <code>/api/v2/financecompletejob</code>
            </span>
          </div>
          <h4>Security</h4>
          <div className="alert alert-light" role="alert">
            API Key
            <p>
              <code>Authorization</code> and <code>ClientKey</code> keys are
              required in the header. See Authentication
            </p>
          </div>
          <h4>Parameters</h4>
          <div className="alert alert-light" role="alert">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Parameter</th>
                  <th scope="col">Description</th>
                  <th scope="col">Parameter Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <code>
                      <b className="text-dark">workRequestId</b>
                    </code>
                  </th>
                  <td>
                    <code className="text-dark">
                      The workRequestId of the job relating to the message
                    </code>
                  </td>
                  <td>
                    <code className="text-dark">body</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code className="text-dark">invoiceDate</code>
                  </th>
                  <td>
                    <code className="text-dark">
                      date formatted yyyy-MM-ddTHH:mm:ss
                    </code>
                    <br />
                    <code className="text-dark">example</code>{" "}
                    <code>2023-12-01T14:05:00</code>
                  </td>
                  <td>
                  <code className="text-dark">body</code>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <code className="text-dark">invoiceValue</code>
                  </th>
                  <td>
                    <code className="text-dark">
                      Full and final value relating to the cost of the entire job.  This value must match
                      the invoice value
                    </code>
                    <br />
                    <code className="text-dark">example</code>{" "}
                    <code>456.78</code>
                  </td>
                  <td>
                  <code className="text-dark">body</code>
                  </td>
                </tr>

                <tr>
                  <th scope="row">
                    <code className="text-dark">message</code>
                  </th>
                  <td>
                    <code className="text-dark">
                      The body of the request will be treated as the message
                    </code>
                  </td>
                  <td>
                    <code className="text-dark">body</code>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="card text-bg-light mb-3">
            <div className="alert alert-success pb-2 pt-2" role="alert">
              <h5>
                {" "}
                <span className="largefont"> &#8226;</span> 200
              </h5>
            </div>

            <div className="card-body">Success</div>
          </div>
        </div>
        <div className="col-4">
          <div className="alert alert-light" role="alert">
            <section>
              <code>
                curl --location
                'http://localhost:8080/api/addmessage/:workRequestId' \
                --header 'Authorization: AUTH_KEY' \ --header 'ClientKey:
                CLIENT_KEY' \ --header 'Content-Type: text/plain' \ --data
                'NOTE_TEXT'
              </code>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default CompleteJob
